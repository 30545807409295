import React, { useState, useRef, useEffect } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import CloudinaryVideoPlayer from "../modules/CloudinaryVideoPlayer"
import CloudinaryVideoThumbnail from "../molecules/CloudinaryVideoThumbnail"
import BuyProductPanel from "../modules/BuyProductPanel"
import { useTranslation } from "react-i18next"

import {
  ArrowDown16,
  ChevronDown16,
  CloseOutline20,
  Rotate16,
  // Favorite16,
  // Renew16,
  Location16,
  CaretLeft16,
  CaretRight16,
  /*  Pdf16,
  Document16, */
} from "@carbon/icons-react"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"

import Slider from "react-slick"
/* import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link" */
import { Dialog, Transition } from "@headlessui/react"

const MissingImage = ({ imageUrl }) => {
  return (
    <div className="w-full py-4 lg:w-1/2 px-grid">
      <div className="block w-full h-full overflow-hidden border border-gray-100 group">
        <Image
          fillColor="ffffff"
          aspectRatio="1by1"
          className="w-full h-auto"
          image={{ filename: imageUrl }}
          lazyLoad={false}
        />
      </div>
    </div>
  )
}

const ProductThumbnail = ({ imageUrl, className, index, clickHandler }) => {
  return (
    <div className={className}>
      <button
        className="block w-full h-full overflow-hidden border border-gray-100 group"
        onClick={() => clickHandler(index)}
      >
        <Image
          fillColor="ffffff"
          aspectRatio="1by1"
          className="w-full h-auto duration-300 transform group-hover:scale-102"
          image={imageUrl}
          lazyLoad={false}
        />
      </button>
    </div>
  )
}

const ProductThumbnailSlider = ({ itemCount, children }) => {
  // Slick Slider

  function NextArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <CaretRight16 />
        </span>
      </span>
    )
  }

  function PrevArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <CaretLeft16 />
        </span>
      </span>
    )
  }

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    arrows: true,
    mobileFirst: true,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button page={i + 1}></button>,

    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 1055,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 671,
        settings: {
          infinite: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
      {itemCount.length > 1 ? (
        <Slider
          className="flex flex-row flex-wrap mb-8 md:mb-0 md:-my-4 -mx-grid"
          {...settings}
        >
          {children}
        </Slider>
      ) : (
        <div className="flex flex-row flex-wrap mb-8 md:mb-0 md:-my-4 -mx-grid">
          {children}
        </div>
      )}
    </>
  )
}

const ProductOverviewBlock = ({ block }) => {
  const { i18n, t } = useTranslation()
  const [activeInfosState, setActiveInfosState] = useState(null)
  const [showThrixtyModal, setShowThrixtyModal] = useState(false)
  const [showBuyModal, setShowBuyModal] = useState(false)

  const dealerLinks = process.env.GATSBY_DEALER_SEARCH_PAGE_PATH
    ? JSON.parse(process.env.GATSBY_DEALER_SEARCH_PAGE_PATH)
    : null

  const forceNexmartOnly =
    process.env.GATSBY_FORCE_NEXMART_WITHOUT_DEALER_SEARCH

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [activeModalImage, setActiveModalImage] = useState(-1)

  const openModalHandler = (marker) => {
    if (typeof marker !== "string") {
      setActiveModalImage(marker)
      setIsModalOpen(true)
    } else {
      if (marker === "thrixty") {
        setShowThrixtyModal(true)
        setIsModalOpen(true)
      } else if (marker === "buy") {
        window.adf && adf.ClickTrack({}, 2861695, "Button Kaufen", {})
        setShowBuyModal(true)
        setIsModalOpen(true)
      }
    }
  }

  const closeModalHandler = () => {
    setActiveModalImage(-1)
    setShowThrixtyModal(false)
    setShowBuyModal(false)
    setIsModalOpen(false)
  }

  const toggleInfos = (index) => {
    const newActiveInfosState = activeInfosState !== index ? index : null
    setActiveInfosState(newActiveInfosState)
  }

  const refInfoContainers = useRef([])

  const refInfoContainersCollection = (el) => {
    if (el && !refInfoContainers.current.includes(el)) {
      refInfoContainers.current.push(el)
    }
  }

  // Workaround: Verhindert falsche Start-Höhe des Contents, weil Bild zu lange zum Laden braucht. => Refactoring
  useEffect(() => {
    setTimeout(() => {
      setActiveInfosState(0)
    }, 1000)
  }, [])

  let relevantVideos = []

  if (block.product_videos && block.product_videos.length > 1) {
    // filter on video ending with DE, in any other language use EN
    if (i18n.language === "de") {
      relevantVideos = block.product_videos.filter((videoFilename) => {
        return videoFilename?.toLowerCase().split(".")[0].endsWith("de")
      })
    } else {
      relevantVideos = block.product_videos.filter((videoFilename) => {
        return videoFilename?.toLowerCase().split(".")[0].endsWith("en")
      })
    }
  }

  let galleryObjects = block.product_images.map((img) => {
    if (img === "undefined") {
      return { type: "noimage", url: process.env.GATSBY_IMG_PLACEHOLDER }
    } else {
      return { type: "image", url: img }
    }
  })

  // append video(s) if it fits in the total 4 items limit
  if (galleryObjects.length + relevantVideos.length <= 4) {
    galleryObjects.push(
      ...relevantVideos.map((video) => {
        return { type: "video", url: video }
      })
    )
  } else {
    // otherwise insert the videos
    relevantVideos.forEach((video, index) => {
      galleryObjects.splice(4 - relevantVideos.length, 0, {
        type: "video",
        url: video,
      })
    })
  }

  let thrixtyIframeUrl = null
  if (
    block.product_thrixty_views &&
    block.product_thrixty_views.length > 0 &&
    block.product_thrixty_views[0]
  ) {
    thrixtyIframeUrl = `${process.env.GATSBY_THRIXTY_IFRAME_URL}${
      block.product_thrixty_views[0].split(".")[0]
    }`
  }

  return (
    <BlockWrapper
      block={block}
      blockPadding="pt-12 pb-16"
      blockWidth="container"
      showHeadline="false"
    >
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className="relative w-full md:w-1/2 lg:w-2/3 xl:w-3/4 px-grid">
          {thrixtyIframeUrl && (
            <button
              className="absolute top-0 z-10 left-4 btn hover:text-primary"
              onClick={() => openModalHandler("thrixty")}
            >
              <Rotate16 className="inline-block mr-2 -mt-0.5 align-middle" />
              {t("generic.products.thrixty_link_text")}
            </button>
          )}
          <ProductThumbnailSlider itemCount={galleryObjects.length}>
            {galleryObjects.length > 0 &&
              galleryObjects.map((obj, index) =>
                index < 4 ? (
                  <React.Fragment key={obj.url}>
                    {obj.type === "image" && (
                      <ProductThumbnail
                        className="w-full py-4 lg:w-1/2 px-grid"
                        imageUrl={obj.url}
                        index={index}
                        clickHandler={openModalHandler}
                      />
                    )}
                    {obj.type === "noimage" && (
                      <MissingImage imageUrl={obj.url} />
                    )}
                    {obj.type === "video" && (
                      <CloudinaryVideoThumbnail
                        videoFilename={obj.url}
                        index={index}
                        clickHandler={openModalHandler}
                      />
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment key={obj.url}>
                    {obj.type === "image" && (
                      <ProductThumbnail
                        className="w-full py-4 lg:w-1/4 xl:w-1/6 px-grid"
                        imageUrl={obj.url}
                        index={index}
                        clickHandler={openModalHandler}
                      />
                    )}
                  </React.Fragment>
                )
              )}
          </ProductThumbnailSlider>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-grid">
          <div
            className="prose max-w-none"
            dangerouslySetInnerHTML={{
              __html: `<p>${block.text.split("\n\n").join("</p><p>")}</p>`,
            }}
          ></div>
          <div className="mt-8">
            <span className="mr-2 font-bold">
              {t("generic.products.choosen_variant")}:
            </span>
            {block.choosen_variant}
          </div>
          <div className="mt-2">
            <span className="mr-2 font-bold">
              {t("generic.products.article_no")}:
            </span>
            {block.choosen_sku}
          </div>
          {block.has_variants === "true" && (
            <Button
              className="flex w-full mt-4 text-left"
              btnType="secondary"
              link="#variants"
            >
              {t("generic.products.choose_other_variant")}
              <ArrowDown16 className="inline-block h-6 ml-auto -mt-0.25 align-middle" />
            </Button>
          )}
          {/* <div className="flex flex-row mt-4 -mx-2">
            <div className="w-1/2 px-2">
              <Button className="flex w-full" btnType="outline" link="/">
                Merken
                <Favorite16 className="ml-auto h-6 inline-block -mt-0.25 align-middle" />
              </Button>
            </div>
            <div className="w-1/2 px-2">
              <Button className="flex w-full" btnType="outline" link="/">
                Vergleichen
                <Renew16 className="ml-auto h-6 inline-block -mt-0.25 align-middle" />
              </Button>
            </div>
          </div> */}
          {(dealerLinks && dealerLinks[i18n.language]) ||
            (forceNexmartOnly && (
              <Button
                className="flex w-full mt-4 text-left"
                btnType="primary"
                onClick={() => openModalHandler("buy")}
              >
                {t("generic.products.buy")}
                <Location16 className="inline-block h-6 ml-auto -mt-0.25 align-middle" />
              </Button>
            ))}
          <div className="mt-8 border border-gray-100">
            {block.infos?.length > 0 &&
              block.infos
                .filter((info) => info.content?.length > 0)
                .map((item, index) => (
                  <div
                    key={`variant-infos-${index}`}
                    className="border-b border-white last:border-b-0"
                  >
                    <button
                      className="flex w-full p-4 text-left bg-gray-100 group"
                      onClick={() => toggleInfos(index)}
                    >
                      {item.title}
                      <ChevronDown16
                        className={`inline-block h-6 ml-auto -mt-0.5 align-middle group-hover:mt-0.5 group-hover:-mb-0.5 transition-all duration-300 transform ${
                          activeInfosState === index ? "rotate-180" : ""
                        }`}
                      />
                    </button>
                    <div
                      className="overflow-hidden transition-all duration-500 "
                      ref={refInfoContainersCollection}
                      style={{
                        height: `${
                          activeInfosState === index
                            ? `${refInfoContainers?.current[index]?.scrollHeight}px`
                            : "0px"
                        }`,
                      }}
                    >
                      <ul className="px-4">
                        {item.type === "paragraphs" &&
                          item.content?.length > 0 && (
                            <li className="py-2">
                              {item.content &&
                                item.content.split("\n\n").map((e, i) => {
                                  return (
                                    <p
                                      key={e}
                                      className={`py-2 text-gray-600 border-gray-100${
                                        i > 0 ? " border-t" : ""
                                      }`}
                                    >
                                      {e}
                                    </p>
                                  )
                                })}
                            </li>
                          )}
                        {item.type === "listitems" && item.content?.length > 0 && (
                          <>
                            {item.content.map((inneritem, innerindex) => (
                              <li
                                key={inneritem}
                                className={`py-2 ${
                                  innerindex > 0
                                    ? "border-t border-gray-100"
                                    : ""
                                }`}
                              >
                                {inneritem}
                              </li>
                            ))}
                          </>
                        )}
                        {item.type === "links" && item.content?.length > 0 && (
                          <>
                            {item.content.map((inneritem, innerindex) => (
                              <li
                                key={inneritem.label + inneritem.fileName}
                                className={`py-2 ${
                                  innerindex > 0
                                    ? "border-t border-gray-100"
                                    : ""
                                }`}
                              >
                                <a
                                  href={inneritem.link}
                                  rel="noopener noreferrer"
                                  className={`${
                                    inneritem.link?.length > 0
                                      ? "hover:text-primary-hover"
                                      : ""
                                  }`}
                                >
                                  {inneritem.label ? (
                                    <>
                                      {inneritem.label}
                                      {inneritem.fileName && (
                                        <>({inneritem.fileName})</>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {inneritem.fileName && (
                                        <>{inneritem.fileName}</>
                                      )}
                                    </>
                                  )}
                                </a>
                              </li>
                            ))}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>

      <Transition appear show={isModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto "
          onClose={() => closeModalHandler()}
        >
          <Dialog.Overlay className="fixed inset-0 flex bg-black bg-opacity-50">
            <div className="self-center w-full block-w-container">
              {activeModalImage > -1 && (
                <>
                  {galleryObjects &&
                    galleryObjects[activeModalImage].type === "image" && (
                      <div className="h-[calc(100vh_-_4rem)] bg-white relative m-4">
                        <button
                          className="absolute right-5 top-5"
                          onClick={() => closeModalHandler()}
                        >
                          <CloseOutline20 />
                        </button>

                        <Image
                          className="object-contain w-full h-full p-8"
                          image={galleryObjects[activeModalImage].url}
                          imageWidth={1500}
                        />
                      </div>
                    )}
                  {galleryObjects &&
                    galleryObjects[activeModalImage].type === "video" && (
                      <div className="relative w-full bg-white">
                        <button
                          className="absolute z-20 right-5 top-5"
                          onClick={() => closeModalHandler()}
                        >
                          <CloseOutline20 className="text-white" />
                        </button>
                        <CloudinaryVideoPlayer
                          videoFilename={galleryObjects[activeModalImage].url}
                        />
                      </div>
                    )}
                </>
              )}
              {showThrixtyModal && thrixtyIframeUrl && (
                <div className="h-[calc(100vh_-_4rem)] bg-white relative m-4">
                  <button
                    className="absolute z-20 right-5 top-5"
                    onClick={() => closeModalHandler()}
                  >
                    <CloseOutline20 />
                  </button>
                  <iframe
                    title={t("generic.products.thrixty_frame_title")}
                    className="object-contain w-full"
                    style={{ aspectRatio: "4 / 3" }}
                    src={thrixtyIframeUrl}
                  ></iframe>
                </div>
              )}
              {showBuyModal && (
                <div className="h-[calc(100vh_-_4rem)] bg-white relative m-4">
                  <button
                    className="absolute z-20 right-4 top-4"
                    onClick={() => closeModalHandler()}
                  >
                    <CloseOutline20 />
                  </button>
                  <BuyProductPanel
                    sku={block.choosen_sku}
                    suppressNexmart={
                      process.env.GATSBY_GLOBAL_PATH_PREFIX !== "de" &&
                      process.env.GATSBY_GLOBAL_PATH_PREFIX !== "pt" &&
                      process.env.GATSBY_GLOBAL_PATH_PREFIX !== "es"
                    }
                    suppressDealerSearch={forceNexmartOnly}
                    showNoRetailersFoundHint={process.env.GATSBY_GLOBAL_PATH_PREFIX === "pt" ||
                      process.env.GATSBY_GLOBAL_PATH_PREFIX === "es"}
                  />
                </div>
              )}
            </div>
          </Dialog.Overlay>
        </Dialog>
      </Transition>
    </BlockWrapper>
  )
}

export default ProductOverviewBlock
